import { Image } from "image-js";

const addCircleMaskToImageData = (imageData) => {
    let maskedImg = new Image(imageData.width, imageData.height);

    // Define the center and radius of the circle
    const centerX = imageData.width / 2;
    const centerY = imageData.height / 2;
    const radius = Math.min(centerX, centerY);
    const maskFill = [...Array(imageData.channels)].map((_) =>  255);
    // console.log(maskFill)

    // Loop through all the pixels of the new image
    for (let y = 0; y < maskedImg.height; y++) {
        for (let x = 0; x < maskedImg.width; x++) {
            // Check if the pixel is inside the circle
            const distance = Math.sqrt((x - centerX) ** 2 + (y - centerY) ** 2);
            if (distance < radius) {
                // Set the alpha value to opaque
                
                maskedImg.setPixelXY(x, y, maskFill);
            }
        }
    }

    maskedImg = maskedImg.invert();
    
    return imageData.subtractImage(maskedImg);
};

export default addCircleMaskToImageData;
