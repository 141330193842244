import ReactGA from "react-ga4";
import Wrapper from "components/UI/Wrapper/Wrapper";
// import { useWindowSize } from "@react-hook/window-size";
// import EnterNowAnimation from "components/UI/EnterNowAnimation/EnterNowAnimation";
import "./style.scss";

const EnterNow = ({ isMobile }) => {
	// const [wWidth, wHeight] = useWindowSize({ wait: 500 });

	function linkClickHandler(action, label) {
		ReactGA.event({
			category: "Footer Enter Now section",
			action,
			label,
		});
	}

	return (
		<section className="section primary-bg">
			<Wrapper className="enternow-wrapper" />
			{/* <EnterNowAnimation width={wWidth} height={wHeight} /> */}
			<Wrapper className="enternow-wrapper">
				<h1 className="enter-now-link">
					{/* rome-ignore lint/a11y/useValidAnchor: <explanation> */}
					<a
						href="https://www.judgify.me/rgdbranding23"
						target="_blank"
						rel="noreferrer"
						onClick={() => linkClickHandler("CTA link clicked", "Enter now link")}
					>
						{isMobile ? (
							<span>Enter Now&nbsp;&nbsp;↗</span>
						) : (
							<span className="header-left">
								<span>Enter Now&nbsp;</span>
								<br />
								<span>↗</span>
							</span>
						)}
					</a>
				</h1>
				<div className="sponsors">
					<h2>Thank you sponsors</h2>
					<h3>
						Partners:{" "}
						{/* rome-ignore lint/a11y/useValidAnchor: <explanation> */}
						<a
							target="_blank"
							rel="noreferrer"
							href="https://www.domtar.com/en"
							onClick={() => linkClickHandler("Partners link clicked", "Domtar")}
						>
							Domtar
						</a>{" "}
						/ {/* rome-ignore lint/a11y/useValidAnchor: <explanation> */}
						<a
							target="_blank"
							rel="noreferrer"
							href="https://www.moveable.com/"
							onClick={() => linkClickHandler("Partners link clicked", "Moveable")}
						>
							Moveable
						</a>{" "}
						/ {/* rome-ignore lint/a11y/useValidAnchor: <explanation> */}
						<a
							target="_blank"
							rel="noreferrer"
							href="https://rethinkideas.com/"
							onClick={() => linkClickHandler("Partners link clicked", "Rethink")}
						>
							Rethink
						</a>{" "}
						/ {/* rome-ignore lint/a11y/useValidAnchor: <explanation> */}
						<a
							target="_blank"
							rel="noreferrer"
							href="https://posterdisplay.com/en/"
							onClick={() => linkClickHandler("Partners link clicked", "AFIX")}
						>
							AFIX
						</a>
						<br />
						Supporters:{" "}
						{/* rome-ignore lint/a11y/useValidAnchor: <explanation> */}
						<a
							target="_blank"
							rel="noreferrer"
							href="https://www.underconsideration.com/brandnew/"
							onClick={() => linkClickHandler("Supporters link clicked", "Brandnew")}
						>
							BrandNew
						</a>{" "}
						/ {/* rome-ignore lint/a11y/useValidAnchor: <explanation> */}
						<a
							target="_blank"
							rel="noreferrer"
							href="https://descan.ca/"
							onClick={() => linkClickHandler("Supporters link clicked", "Descan")}
						>
							DesCan
						</a>{" "}
						/ {/* rome-ignore lint/a11y/useValidAnchor: <explanation> */}
						<a
							target="_blank"
							rel="noreferrer"
							href="https://theadcc.ca/"
							onClick={() => linkClickHandler("Supporters link clicked", "The ADDC")}
						>
							The ADDC
						</a>{" "}
						/{" "}
						{/* rome-ignore lint/a11y/useValidAnchor: <explanation> */}
<a
							target="_blank"
							rel="noreferrer"
							href="https://iabccalgary.com/"
							onClick={() => linkClickHandler(
								"Supporters link clicked",
								"IABC  Calgary",
							)}
						>
							IABC&nbsp;Calgary
						</a>
					</h3>
				</div>
			</Wrapper>
		</section>
	);
};

export default EnterNow;
