import { useEffect, useState } from "react";
import ReactGA from 'react-ga4';
import { gsap,ScrollTrigger } from "gsap/all";
import { PixiPlugin } from "gsap/all";
import * as PIXI from "pixi.js";

import useIsMobileView from "hooks/useIsMobileView";
import Home from "pages/Home/Home";

import "assets/scss/styles.scss";
//! import once you have the actual files
// import 'assets/fonts/*.ttf';

// .env variables can be access like the following
// const AWS_POINT = process.env.REACT_APP_AWS_POINT;

// uncomment below if coming soon page is needed
// import ComingSoon from './pages/ComingSoon/ComingSoon';

// Move Get Lang to Context Provider
// Wrap App in Context Provider
// Add TranslatableText
// Add translation functions

// Add Lazy loading example
// Add UI stuff, modal, forms

gsap.registerPlugin(ScrollTrigger);

const App = () => {
    
    const [isMobile, setIsMobile] = useState(true);
    // Use our custom hook for viewports.
    const isMobileView = useIsMobileView();
    // Use our custom hook for language.

    useEffect(() => {
        ReactGA.initialize('G-BQG2VX94Y4');
    },[]);

    useEffect(() => {
        gsap.registerPlugin(PixiPlugin);
        PixiPlugin.registerPIXI(PIXI);
        // Set language
        // Set Desktop or Mobile viewport
        setIsMobile(isMobileView);
    }, [isMobileView]);


    return (
        <Home isMobile={isMobile} />
    );
};

export default App;
