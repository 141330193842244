import React from "react";
import { createPortal } from "react-dom";
import "./style.scss";

const JudgeModal = (props) => {
	const { showModal, hideBackdropClick } = props;


	return createPortal(
		<>
			<div className={`backdrop ${showModal ? 'open' : 'close'}`} onClick={hideBackdropClick} />
			<div className={`card ${showModal ? "open" : "close"}`}>
				{props.children}
			</div>
		</>,
		document.getElementById("modal-wrapper"),
	);
};
export default JudgeModal;
