import React, { useRef, useEffect } from "react";
import { ParticleContainer, useTick } from "@pixi/react";

import Batch from "./Batch";
import RImage from "assets/images/RegisteredTM.svg";

const ParticleImage = ({
	imageData,
	width,
	height,
	position,
	displaySize,
	particleWidth,
	particleHeight,
	padding,
}) => {
	const containerRef = useRef(null);

	const myBatch = useRef(null);

	const config = {
		dotCount: 9000,
		properties: {
			position: true,
			scale: true,
			vertices: true,
			alpha: true,
		},
	};
	

	useEffect(() => {
		if (!containerRef.current) return;
		if (!myBatch.current) {
			myBatch.current = new Batch({
				count: config.dotCount,
				particleImage: RImage,
				padding,
				viewWidth: width,
				viewHeight: height,
				position,
				displaySize,
				particleWidth,
				particleHeight,
			})
			myBatch.current.init(containerRef.current);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [containerRef.current]);

	useEffect(() => {
		if (!myBatch.current) return;

		myBatch.current.layoutChange(
			position,
			displaySize,
			imageData,
			containerRef.current,
		);
	}, [position, displaySize, imageData]);

	useTick(() => {
		// console.log('is updating')
		if (!myBatch.current) return;
		myBatch.current.update(
			containerRef.current,
			imageData.width,
			imageData,
		);
	});

	return (
		<ParticleContainer
			ref={containerRef}
			batchSize={config.dotCount}
			maxSize={config.dotCount}
			properties={config.properties}
			autoResize
		/>
	);
};

export default ParticleImage;
