import ReactGA from 'react-ga4';
import "./style.scss";
import Wrapper from "components/UI/Wrapper/Wrapper";

const CallToEntries = ({ isMobile }) => {
    function linkClickHandler(action, label) {
		ReactGA.event({
			category: "Call for Entries section",
			action,
			label,
		});
	}
    return (
        <section className="section primary-bg section-calltoentries">
            <Wrapper className="wrapper-calltoentries">
                <h1>
                    Call
                    <br />
                    for
                    <br />
                    entries
                    <br />
                    now&nbsp;open <br />
                    {/* rome-ignore lint/a11y/useValidAnchor: <explanation> */}
                    <a
                        href="https://www.judgify.me/rgdbranding23"
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => linkClickHandler("CTA link clicked", "Enter now")}
                    >
                        Enter now&nbsp;&nbsp;↗
                    </a>
                </h1>
            </Wrapper>
        </section>
    );
};

export default CallToEntries;
