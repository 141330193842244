import { Fragment } from "react";
import ReactGA from 'react-ga4';
import "rc-collapse/assets/index.css";
import "./style.scss";
import motion from "./motionUltils.ts";

import Wrapper from "components/UI/Wrapper/Wrapper";
import Divider from "components/UI/Divider/Divider";
import Collapse from "rc-collapse";
import zeroFill from "zero-fill";
const Panel = Collapse.Panel;

const integratedData = [
    {
        header: "Branding Programs",
        info: "A combination of multiple branded material/channels into a cohesive program (e.g. collateral, signage, website, social media and more). This award includes a sweeping design system for a given brand identity.",
    },
    {
        header: "Branding Identity – New Brand",
        info: "Identities for brands that did not previously exist (e.g., Identity guides, brand strategy guides). This award goes to brands designed from scratch: a fresh company; or a completely fresh identity.",
    },
    {
        header: "Branding Identity – Rebrand & Refresh",
        info: "New or updated identities for existing brands. This category includes entries that reflect on a brand’s present identity, and push it forward with a new take on the design.",
    },
];

const elementData = [
    {
        header: "Logo / Wordmark",
        info: "Any graphic mark, emblem, symbol or abstract/figurative that represents an organization, product, event and beyond.",
    },
    {
        header: "Packaging",
        info: "Functional design that holds any product, or allows for that product to be travelled with / protected / contained.",
    },
    {
        header: "Environment/Experimental",
        info: "Designs that address our surroundings. Can include branded spaces such as events, hospitality, pop-up environments, retail and workspaces.",
    },
    {
        header: "Digital",
        info: "Any design that makes use of technology, data and digital platforms to communicate a brand identity. Can include websites, apps, social media, XR and more.",
    },
    {
        header: "Print",
        info: "Any printed initiative that communicates a brand identity. Can include stationery, catalogues, calendars, collateral material and more.",
    },
    {
        header: "Motion",
        info: "Any design that uses animation or filmmaking to enhance a brand identity. Can include commercials, explainer videos and more.",
    },
    {
        header: "Other",
        info: "Any elements of a brand that live outside of the present categories. This might include clothing, typeface, awards and more.",
    },
];

const sectorData = [
    {
        header: "Student",
        info: "Work by individuals currently enrolled in a recognized post-secondary educational institution.",
    },
    {
        header: "Large Creative Agency/Business",
        info: "Work by and/or for a for-profit company where either the creative agency and/or the client comprises more than 25 employees.",
    },
    {
        header: "Medium Creative Agency/Business",
        info: "Work by and/or for a for-profit company where either the creative agency and/or the client comprises six to 25 employees.",
    },
    {
        header: "Small Creative Agency/Business",
        info: "Work by and/or for a for-profit company where either the creative agency and/or the client comprises less than 6 employees.",
    },
    {
        header: "Large Non-Profit/Institution/Government",
        info: "Work by or for a non-profit organization, or governmental body, agency or department that comprises more than 25 employees.",
    },
    {
        header: "Medium Non-Profit/Institution/Government",
        info: "Work by or for a non-profit organization, or governmental body, agency or department that comprises six to 24 employees.",
    },
    {
        header: "Small Non-Profit/Institution/Government",
        info: "Work by or for a non-profit organization, or governmental body, agency or department that comprises less than six employees.",
    },
];

const Categories = ({ isMobile }) => {
    function accordionOpended(action, label) {
		ReactGA.event({
			category: "Categories section",
			action,
			label,
		});
	}
    return (
        <>
            <section
                className="section secondary-bg section-categories"
                id="categories"
            >
                <Wrapper className="categories-wrapper">
                    <h2>Categories</h2>
                    <h3>Integrated Awards</h3>
                </Wrapper>
                <Wrapper className="accordion-wrapper">
                    <Collapse
                        accordion={true}
                        className="categories-accordion"
                        openMotion={motion}
                    >
                        {/* section 1 */}
                        {integratedData.map((d, i) => (
                            <Fragment key={`${i}-integrated`}>
                                <Panel
                                    header={d.header}
                                    extra=""
                                    expandIcon={(props) => (
                                        <p>{zeroFill(2, i + 1)}&nbsp;&nbsp;</p>
                                    )}
                                    onItemClick={() => accordionOpended("Opened accordion: Integrated Awards", d.header)}
                                >
                                    {d.info}
                                </Panel>
                                <Divider />
                            </Fragment>
                        ))}
                    </Collapse>
                </Wrapper>
                <Wrapper className="categories-wrapper">
                    <h3>Element Awards</h3>
                </Wrapper>
                <Divider />
                <Wrapper className="accordion-wrapper">
                    <Collapse
                        accordion={true}
                        className="categories-accordion"
                        openMotion={motion}
                    >
                        {elementData.map((d, i) => (
                            <Fragment key={`${i}-element`}>
                                <Panel
                                    header={d.header}
                                    extra=""
                                    expandIcon={(props) => (
                                        <p>{zeroFill(2, i + 1)}&nbsp;&nbsp;</p>
                                    )}
                                    onItemClick={() => accordionOpended("Opened accordion: Element Awards", d.header)}
                                >
                                    {d.info}
                                </Panel>
                                <Divider />
                            </Fragment>
                        ))}
                    </Collapse>
                </Wrapper>
                <Wrapper className="categories-wrapper">
                    <h3>Sector Awards</h3>
                </Wrapper>
                <Divider />
                <Wrapper className="accordion-wrapper">
                    <Collapse
                        accordion={true}
                        className="categories-accordion"
                        openMotion={motion}
                    >
                        {sectorData.map((d, i) => (
                            <Fragment key={`${i}-sector`}>
                                <Panel
                                    header={d.header}
                                    extra=""
                                    expandIcon={(props) => (
                                        <p>{zeroFill(2, i + 1)}&nbsp;&nbsp;</p>
                                    )}
                                    onItemClick={() => accordionOpended("Opened accordion: Sector Awards", d.header)}
                                >
                                    {d.info}
                                </Panel>
                                <Divider />
                            </Fragment>
                        ))}
                    </Collapse>
                </Wrapper>
            </section>
        </>
    );
};

export default Categories;
