import { sortByLastName } from "utils/utils";
import { Image } from "image-js";
import addCircleMaskToImageData from "utils/addCircleMaskToImageData";

import ShaunImg from "./images/bio/shaun_loftman.jpg";
import AstridImg from "./images/bio/astrid_stavro.jpg";
import LauraImg from "./images/bio/stein_laura.jpg";
import HansImg from "./images/bio/hans_thiessen.jpg";
import AdeleImg from "./images/bio/adele_bisaillon.jpg";
import JoelImg from "./images/bio/joel_harding.jpg";
import ForestImg from "./images/bio/forest_young.jpg";
import HectorImg from "./images/bio/hector_crespo.jpg";
import RonaldImg from "./images/bio/ronald_tau.jpg";
import HelenImg from './images/bio/helen_keyes.jpg';
import JenniferImg from './images/bio/jennifer_kinon.jpg';
import MargaretImg from './images/bio/margaret_lee.jpg';
import KseniaImg from './images/bio/ksenia_starikova.jpg';
import RichardImg from './images/bio/richard_belanger.jpg';

import ShaunParticleImg from "./images/particle/shaun_loftman.jpg";
import AstridParticleImg from "./images/particle/astrid_stavro.jpg";
import LauraParticleImg from "./images/particle/stein_laura.jpg";
import HansParticleImg from "./images/particle/hans_thiessen.jpg";
import AdeleParticleImg from "./images/particle/adele_bisaillon.jpg";
import JoelParticleImg from "./images/particle/joel_harding.jpg";
import ForestParticleImg from "./images/particle/forest_young.jpg";
import HectorParticleImg from "./images/particle/hector_crespo.jpg";
import RonaldParticleImg from "./images/particle/ronald_tau.jpg";
import HelenParticleImg from './images/particle/helen_keyes.jpg';
import JenniferParticleImg from './images/particle/jennifer_kinon.jpg';
import MargaretParticleImg from './images/particle/margaret_lee.jpg';
import KseniaParticleImg from './images/particle/ksenia_starikova.jpg';
import RichardParticleImg from './images/particle/richard_belanger.jpg';

let loaded = false;

const judgeData = sortByLastName(

	[
	{
		name: "Ronald Tau RGD",
		title: "Founder and Creative Director",
		comp: "Meat Studio",
		loc: "Toronto, Canada / Beijing, China",
		photo: RonaldImg,
		particlePhoto: RonaldParticleImg, 
		bio: "Ronald is a Chinese-Canadian art director and graphic designer specializing in creating visual identities. Born in Hong Kong, raised in Toronto and relocated to Beijing, Ronald creates in multicultural creative contexts, crossing cultural borders through creativity and design. Ronald’s work has been covered and/or awarded in a variety of awards and media, ranging from the Tokyo Type Directors Club (Japan), It’s Nice That (U.K.), the Museum of New York (US), Asia-Pacific Design (China), Typojanchi (Korea).",
	},
	{
		name: "Shaun Loftman",
		title: "Executive Creative Director, CEEMEA",
		comp: "Landor & Fitch",
		loc: "London, UK",
		photo: ShaunImg,
		particlePhoto: ShaunParticleImg,
		bio: "With over 25 years of experience, Shaun drives the overall creative vision across the CEEMEA region for Landor & Fitch, one of the world’s largest brand consulting firms. He leads brand transformation programs for a variety of commercial and corporate clients across categories and sectors including packaging, destination branding, financial, energy and retail. Shaun specializes in creative strategy, brand positioning, identity, brand architecture, visual and experiential expression, with hero launches including for Joali, a sustainable luxury hospitality; DXB; Saudi Space Commission and the Azerbaijan nation branding.",
	},
	{
		name: "Astrid Stavro",
		title: "Vice President, Creative Director",
		comp: "COLLINS",
		loc: "London, UK",
		photo: AstridImg,
		particlePhoto: AstridParticleImg,
		bio: "Astrid is an award-winning creative director with almost two decades of leadership in design. She directed her own studio in Barcelona for 10 years before co-founding the design consultancy Atlas, where she worked with Camper, Barcelona Design Museum, MAST chocolates, Wallpaper* and Jasper Morrison, amongst others. In 2018, she joined Pentagram as a Partner, leading her team on projects for clients who included Vitra, Tate, Taittinger, McKinsey & Company, The National Portrait Gallery, PORT magazine and ACCIONA. Her work has received over 200 awards and has been featured in numerous publications, including Eye magazine and Creative Review.",
	},
	{
		name: "Laura Stein RGD",
		title: "Chief Creative Officer",
		comp: "Bruce Mau Design",
		loc: "Toronto, Canada",
		photo: LauraImg,
		particlePhoto: LauraParticleImg,
		bio: "Bruce Mau Design is a research-based design studio delivering inventive and rallying design across all the places where people connect. Home to an international community, BMD works across borders and cultures, helping organizations at every scale grow, transform and deliver indelible experiences. As Partner and Chief Creative Officer and long-time advocate for collaboration, Laura provides overall creative and strategic direction across a variety of project types, from a museum in Abu Dhabi to a greenway in Austin to a global performance brand based in Tokyo.",
	},
	{
		name: "Hans Thiessen RGD",
		title: "Partner, Creative Director of Design",
		comp: "Rethink",
		loc: "Vancouver, Canada",
		photo: HansImg,
		particlePhoto: HansParticleImg,
		bio: "Hans leads the design team at Rethink – one of Fast Company’s Top 10 Most Innovative Ad Agencies and Canada’s Design Agency of the Year (four years in a row and counting). He has worked with a wide range of global brands, including Kraft Heinz, IKEA, Tazo, Molson Coors, Nike, EA, Mattel and Arc’teryx, non-profits and household names in the making. His work has been recognized with a variety of awards (including a D&AD Black Pencil), press (from Rolling Stone to Ripley’s Believe It or Not) and the occasional cease and desist letter.",
	},
	{
		name: "Adele Bisaillon RGD",
		title: "Senior Creative Communications Advisor",
		comp: "The Government of the Northwest Territories (GNWT)",
		loc: "Yellowknife, Canada",
		photo: AdeleImg,
		particlePhoto: AdeleParticleImg,
		bio: "With over 20 years of experience, Adele is an award-winning designer and art director with creative experience working in government and agencies, both in-house and freelance. As the head brand strategist and graphic designer for the Government of the Northwest Territories, Adele provides overall creative and strategic direction, develops and supports implementation of high-level, cross-department communications strategies and tactics, focusing on government priorities. Adele has an innate ability to translate client directives into creative, award-winning solutions, and is known for out-of-the-box thinking and a commitment to design excellence.",
	},
	{
		name: "Joel Harding RGD",
		title: "Creative Director",
		comp: "Critical Mass",
		loc: "Cranbrook, Canada",
		photo: JoelImg,
		particlePhoto: JoelParticleImg,
		bio: "Joel is a creative director at Critical Mass, a global digital agency voted AdAge’s best place to work. Joel leads creative teams sparking meaningful connections between brands and their audiences through the use of design, technology and storytelling. His work at the intersection of branding, digital experiences and advertising has allowed him to work with a wide range of clients—from the smallest startups to the largest global brands like Nike, Apple, Nissan, Infiniti, Citi and AT&T—winning awards and making friends along the way.",
	},
	{
		name: "Forest Young",
		title: "Global Head",
		comp: "Brand for Rivian",
		loc: "Los Angeles, USA",
		photo: ForestImg,
		particlePhoto: ForestParticleImg,
		bio: "Forest is responsible for the evolution and expression of the Rivian brand across its people, products and services. His team is continually refining the components of brand design, content creation, brand experience and digital design delivery. Prior to Rivian, Forest was the first Chief Creative Officer at Wolff Olins — which was named Fast Company’s Most Innovative Company for Design during his tenure. He is a founding advisor for MillerKnoll's Diversity in Design collaborative and has received the industry's highest accolades, including the Gold Design Lion at Cannes and the Art Directors Club Black Cube.",
	},
	{
		name: "Hector Crespo",
		title: "Head of CX and Design",
		comp: "RBCx at RBC",
		loc: "Toronto, ON",
		photo: HectorImg,
		particlePhoto: HectorParticleImg,
		bio: "Hector and his team work closely with Venture Founders and partners to help them discover new business opportunities, conduct consumer research to gain insights on the human experience and design exceptional and innovative products and services. Hector has worked at advertising and digital agencies. His clients included Fortune 500 companies such as the Cleveland Clinic, Goodyear, Eaton Corporation and sport franchises such as the Cleveland Browns and Columbus Blue Jackets. He has also led digital and mobile strategy and design and delivery teams at financial institutions such as Capital One and Charter One Bank.",
	},
	{
		name: "Helen Keyes",
		title: "Global Head of Brand, Communications, and Digital Design",
		comp: "Philips",
		loc: "Amsterdam, Netherlands",
		photo: HelenImg,
		particlePhoto: HelenParticleImg,
		bio: "Helen has over 30 years of brand and design experience and works within the award-winning Philips Experience Design team.\n\nIn her role, as a member of the global design leadership team, Helen leads the Philips’ brand, communications and digital design teams. Throughout her career, she has worked with major agencies and large international clients across a variety of industry sectors to create unique, long-lasting brands. With a wealth of brand experience from consulting on the agency and client-side, she has lived and worked in London, New York and Amsterdam. Helen is deeply passionate about design, brand and strategy. She firmly believes that good design can transform our everyday experiences for the better.",
	},
	{
		name: "Jennifer Kinon",
		title: "Founding Partner",
		comp: "Champions Design",
		loc: "New York / San Francisco, USA",
		photo: JenniferImg,
		particlePhoto: JenniferParticleImg,
		bio: "Champions Design is a branding and design agency. For more than a decade, the firm has crafted meaningful brand strategy and visual identity systems for some of the world’s best brands. At every point in her career, Jennifer has tackled big systems. She served as Design Director of Hillary for America in 2016, Design Director of New York City’s 2012 Olympic Bid and worked in Pentagram’s New York City office with Michael Bierut. Jennifer earned an MFA from the School of Visual Arts, where she was the first graduate of the program to join its faculty.",
	},
	{
		name: "Margaret Lee",
		title: "Head of Brand & Marketing",
		comp: "Toronto International Film Festival",
		loc: "Toronto, Canada",
		photo: MargaretImg,
		particlePhoto: MargaretParticleImg,
		bio: "Margaret is an award-winning collaborative creative leader with 20+ years of experience in television, advertising, retail, digital, print and film. At TIFF, she leads the creative, content, marketing, TIFF Shop and digital teams while developing effective brand strategy. Margaret was previously the Director of Creative at Rogers Sports and Media and Creative Director for several brands, specializing in brand identity, advertising, campaigns, events and cross-platform creative strategy. She has directed commercials and photoshoots and has taught motion design at OCAD University. She has also worked with clients that include HBO and The New Yorker.",
	},
	{
		name: "Ksenia Starikova",
		title: "CMO Snark.art, OG.art, Founder",
		comp: "Looms.World",
		loc: "London, UK",
		photo: KseniaImg,
		particlePhoto: KseniaParticleImg,
		bio: "From London to New York with Berlin and Zurich in the mix, Ksenia's expertise in creative strategy, curation and design management has taken her around the world, developing new markets, products and services for global brands and emerging start-ups in the context of various creative agencies of WPP. Most recently, while leading Design Hotels brand, her focus shifted towards regenerative and sustainable practices in design, travel and hospitality, subsequently leading to setting up looms.world — a new venture that explores the opportunities and complexities of circular design and the role of new, impact-driven technologies such as blockchain for arts and design within her collaboration with OG.Art.",
	},
	{
		name: "Richard Bélanger",
		title: "Vice President, Design",
		comp: "Cossette",
		loc: "Montreal, Canada",
		photo: RichardImg,
		particlePhoto: RichardParticleImg,
		bio: "Richard is a designer and artist known for his ability to create impactful brands that shape the contemporary landscape, here and abroad. Over the past two decades, Richard has seamlessly fused artistic vision and strategic thinking to drive real business outcomes. This approach has garnered numerous awards from institutions such as Cannes Lions, D&AD and Clio Awards. At Cossette, Richard has led many successful transformation projects for clients such as Can-Am, Just for Laughs and the World Anti-Doping Agency. His work has earned him a reputation as an innovative and sought-after creative in the industry, as he is always looking to break new ground in the world of branding.",
	},
]);

function deepCopy(obj) {
	if (typeof obj !== "object" || obj === null) {
	  return obj; // base case: if obj is not an object or is null, return it as is
	}
	let copy = Array.isArray(obj) ? [] : {};
	for (let key in obj) {
	  copy[key] = deepCopy(obj[key]); // recursively copy nested objects or arrays
	}
	return copy;
  }

async function loadImages(array) {
	if(loaded) return;
	const images = await Promise.all(
		array.map(async ({particlePhoto}) => {
			let image = await Image.load(particlePhoto);
			// const resized = image.resize({ ...displaySize });
			image = image.invert();
			const maskedImg = addCircleMaskToImageData(image);

			return maskedImg;
		})
	);

	images.forEach((imageData, index) => {
		array[index].imageData = imageData;
	});

	loaded = true;
}


export default async function initJudgeData() {
	let returnJudgeData = judgeData.concat(deepCopy(judgeData));
	returnJudgeData = returnJudgeData.map((j,i) => {
		j.keyName = `judge-${i}`;
		return j;
	})
	await loadImages(returnJudgeData);
	return returnJudgeData;
};
