import "./style.scss";
import Wrapper from "components/UI/Wrapper/Wrapper";
import Divider from "components/UI/Divider/Divider";

const tableData = [
    { level: "Student RGD", earlyFee: "$40 / Entry", regFee: "$50 / Entry" },
    {
        level: "Professional RGD Member*",
        earlyFee: "1–3 Entries:\n$65 / Entry\n\n4 or more Entries:\n$50 / Entry",
        regFee: "1–3 Entries:\n$85 / Entry\n\n4 or more Entries:\n$65 / Entry",
    },
    {
        level: "Members of Partner\nOrganizations**",
        earlyFee: "1–3 Entries:\n$75 / Entry\n\n4 or more Entries:\n$65 / Entry",
        regFee: "1–3 Entries:\n$95 / Entry\n\n4 or more Entries:\n$75 / Entry",
    },
    {
        level: "Non-members",
        earlyFee: "1–3 Entries:\n$85 / Entry\n\n4 or more Entries:\n$75 / Entry",
        regFee: "1–3 Entries:\n$105 / Entry\n\n4 or more Entries:\n$85 / Entry",
    },
];

const EntryFees = ({ isMobile }) => {
    function renderTableData() {
        if (isMobile) {
            const early = tableData.map((data) => (
                <li key={`${data.level}-early`} className="cell">
                    {data.level}
                    <br />
                    <br />
                    {data.earlyFee}
                </li>
            ));

            const reg = tableData.map((data) => (
                <li key={`${data.level}-reg`} className="cell">
                    {data.level}
                    <br />
                    <br />
                    {data.regFee}
                </li>
            ));

            return (
                <>
                    <ul>
                        <li className="header-row">
                            <span className="header-main">Early Bird</span>
                            <br />
                            <span className="header-date">
                                (To May 1, 2023)
                            </span>
                        </li>
                        {early}
                    </ul>
                    <ul>
                        <li>
                            <span className="header-main">Regular</span>
                            <br />
                            <span className="header-date">
                                (May 2 – June 16, 2023)
                            </span>
                        </li>
                        {reg}
                    </ul>
                </>
            );
        } else {
            return (
                <ul>
                    <li className="header-row">
                        <div className="cell-level"></div>
                        <div className="cell-early">
                            <span className="header-main">Early bird</span>
                            <br />
                            <span className="header-date">
                                (To May 1, 2023)
                            </span>
                        </div>
                        <div className="cell-reg">
                            <span className="header-main">Regular</span>
                            <br />
                            <span className="header-date">
                                (May 2 - June 16, 2023)
                            </span>
                        </div>
                    </li>
                    {tableData.map((data) => (
                        <li key={data.level}>
                            <div className="cell">{data.level}</div>
                            <div className="cell">{data.earlyFee}</div>
                            <div className="cell">{data.regFee}</div>
                        </li>
                    ))}
                </ul>
            );
        }
    }

    return (
        <>
            <section className="section primary-bg">
                <Divider />
            </section>
            <section
                className="section primary-bg section-entryfees"
                id="entryfees"
            >
                <Wrapper>
                    <h2>Entry Fees</h2>
                </Wrapper>
                <Wrapper className="entryfees-table-wrapper">
                    <div
                        className="table-container"
                        role="table"
                        aria-label="Entry Fees"
                    >
                        {renderTableData()}
                    </div>
                </Wrapper>
                <Wrapper>
                    <div className="legal">
                        <p>
                            All fees are listed in Canadian dollars and are
                            subject to Ontario tax (13%).
                        </p>
                        <br />
                        <p>
                            * includes{" "}
                            <a
                                href="https://www.rgd.ca/rgd-certification/certification"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Certified RGDs
                            </a>
                            ,{" "}
                            <a
                                href="https://www.rgd.ca/membership/recent-graduate.php"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Provisional RGDs
                            </a>
                            ,{" "}
                            <a
                                href="https://www.rgd.ca/membership/jr-affiliate"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Junior Affiliate
                            </a>{" "}
                            &{" "}
                            <a
                                href="https://www.rgd.ca/affiliate.php"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Affiliate Members
                            </a>
                        </p>
                        <p>
                            ** includes members of AIGA, IABC, SDS & ICoD member
                            associations
                        </p>
                        <br />
                        <p>RGD Team Members Receive 3 Free Entries</p>
                    </div>
                </Wrapper>
            </section>
        </>
    );
};

export default EntryFees;
