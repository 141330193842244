import Header from "./sections/Header/Header";
import Categories from "./sections/Categories/Categories";
import CallToEntries from "./sections/CallToEntries/CallToEntries";
import Judges from "./sections/Judges/Judges";
import EnterNow from "./sections/EnterNow/EnterNow";
import Footer from "./sections/Footer/Footer";
import EntryFees from "./sections/EntryFees/EntryFees";
import JudgesAccordion from "./sections/JudgesAccordion/JudgesAccordion";
import { useEffect, useRef } from "react";
import { gsap, ScrollTrigger } from "gsap/all";
import { useWindowSize } from "@react-hook/window-size";
import { useImmer } from "use-immer";
import judgeDataInit from "./sections/Judges/judgedata";

const Home = (props) => {
    const { isMobile } = props;
    const rootRef = useRef();
    const [wWidth,wHeight] = useWindowSize({wait: 300});
    const [judgeData, setJudgeData] = useImmer();
    // const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const loadImages = async () => {
			const data = await judgeDataInit();
			setJudgeData(data);
		};

        const ctx = gsap.context(() => {
            gsap.from(
                ".wrapper-calltoentries",
                {
                    yPercent: -60,
                    // y:"-20%"
                    ease: "sine.out",
                    transformOrigin: "center center",
                    scrollTrigger: {
                        // markers: true,
                        trigger: ".section-calltoentries",
                        start: "top bottom",
                        end: "75% center",
                        scrub: true,
                    },
                }
            );
        }, rootRef.current);

        loadImages();
        return () => ctx.revert();
    }, []);

    useEffect(() => {
        ScrollTrigger.refresh();
    },[wWidth, wHeight])

    

    return (
        <div className="blockwrap" id="top" ref={rootRef}>
            <Header isMobile={isMobile} />
            <Categories isMobile={isMobile} />
            <CallToEntries isMobile={isMobile} />
            <EntryFees isMobile={isMobile} />
            {wHeight > 553 ?  <Judges isMobile={isMobile} judgeData={judgeData} /> : <JudgesAccordion isMobile={isMobile} judgeData={judgeData} /> }
            <EnterNow isMobile={isMobile} />
            <Footer isMobile={isMobile} />
        </div>
    );
};
export default Home;
