import ReactGA from "react-ga4";
import { Stage } from "@pixi/react";
import { useWindowSize } from "@react-hook/window-size";
import { useRef, useCallback } from "react";
import { useImmer } from "use-immer";
import gsap from "gsap";
import ParticleImage from "./subcomponents/ParticleImage";
import useFontFaceObserver from "use-font-face-observer";

import JudgeModal from "./JudgeModal";

import NameWheel from "./subcomponents/NameWheel";
import InfoText from "./subcomponents/InfoText";
import { useEffect } from "react";

import clamp from "clamp";
import map from "utils/map";

const MAX_WIDTH = 1700;
const FONT_FAM = "RegisteredGrotesk-Regular";

const transitionSpeed = 0.5;
let desktopYBack = 90;

const JudgeAnimation = ({ nameData, isMobile }) => {
	const infoTextRef = useRef();
	const nameWheelRef = useRef();
	const modalScrollRef = useRef();
	const playStateRef = useRef(false);

	const isFontLoaded = useFontFaceObserver([
		{ family: "RegisteredGrotesk-Regular" },
	]);

	const [wWidth, wHeight] = useWindowSize();
	const [clampedWin, setClampedWin] = useImmer({
		width: clamp(wWidth, 0, MAX_WIDTH),
		height: clamp(wHeight, 500, 1000),
	});

	const [showModal, setShowModal] = useImmer(false);
	const [closeEnabled, setCloseEnabled] = useImmer(false);

	const [particleConfig, setParticleConfig] = useImmer({
		padding: 20,
		particleSize: 5,
		displaySize: { ...clampedWin },
		position: { x: wWidth / 2, y: 300 },
		maxSize: { width: 700, height: 700 },
	});

	const [infoTextSettings, setInfoTextSettings] = useImmer({
		position: { x: 10, y: 10 },
		fontRemSize: 1,
	});
	const [nameWheelSettings, setNameWheelSettings] = useImmer({
		ringSize: 100,
		fontRemSize: 1,
		arrowXSpacing: 30,
		arrowY: -clampedWin.y,
	});

	const [selectedName, setSelectedName] = useImmer(0);

	const handleNextName = useCallback(() => {
		if (playStateRef.current) return;
		const newPos = (selectedName + 1) % nameData.length;
		setSelectedName(newPos);
	}, [playStateRef, selectedName, nameData]);

	const handlePrevName = useCallback(() => {
		if (playStateRef.current) return;
		const newPos =
			selectedName - 1 < 0 ? nameData.length - 1 : selectedName - 1;
		setSelectedName(newPos);
	}, [playStateRef, selectedName, nameData]);

	const handleNameClick = useCallback(
		(nameIndex) => {
			if (playStateRef.current) return;
			setSelectedName(nameIndex);
		},
		[playStateRef],
	);

	useEffect(() => {
		// if (wWidth >= MAX_WIDTH && wWidth >= clampedWin.width) return;
		if (wWidth >= MAX_WIDTH && clampedWin.width >= MAX_WIDTH) return;
		setClampedWin((draft) => {
			const width = clamp(wWidth, 0, MAX_WIDTH);
			const ratio = 0.8;
			const newClamp = {
				...draft,
				width,
				height: isMobile ? clamp(wHeight - 50, 100, 800) : width * ratio,
			};
			return newClamp;
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [wWidth, wHeight, isMobile, nameData, clampedWin.width]);

	useEffect(() => {
		setNameWheelSettings((draft) => {
			let ringSize = 0;
			if (isMobile) {
				ringSize = clamp((clampedWin.width / 100) * 200, 20, 300);
			} else {
				ringSize = clamp((clampedWin.width / 1000) * 90, 20, 200);
			}
			let fontRemSize = isMobile
				? map(clampedWin.width, [0, MAX_WIDTH], [0.8, 1.2])
				: map(clampedWin.width, [0, MAX_WIDTH], [0.5, 1.2]);
			let arrowY = -ringSize + 30;
			return { ...draft, ringSize, fontRemSize, arrowY };
		});

		setInfoTextSettings((draft) => {
			let fontRemSize = map(clampedWin.width, [0, MAX_WIDTH], [0.5, 1]);
			// let arrowXSpacing = map(clampedWin.width, [0, 1920], [10, 35]);
			return { ...draft, fontRemSize };
		});

		setParticleConfig((draft) => {
			let padding = 30;
			// debugger;

			let maxSize = {
				width: isMobile ? 400 : 700,
				height: isMobile ? 400 : 800,
			};

			const desiredSize = {
				width: isMobile ? clampedWin.width : clampedWin.width / 2 - padding * 2,
				height: isMobile ? clampedWin.width : clampedWin.height / 1.2,
			};

			const clampWidth = clamp(desiredSize.width, 0, maxSize.width);
			const clampHeight = clamp(
				isMobile ? clampedWin.height * .4 : desiredSize.height,
				0,
				maxSize.height,
			);
			const dSize = Math.min(clampWidth, clampHeight);
			let displaySize = { width: dSize, height: dSize };

			let position = {
				x: isMobile ? clampedWin.width / 2 : clampedWin.width * 0.27,
				y: isMobile
					// ? Math.max(desiredSize.height / 3, clampedWin.height / 4) + padding
					// ? displaySize.height /2 + padding
					? displaySize.height * 0.45 + padding
					: clampedWin.height / 2 - desktopYBack,
			};

			const newSettings = { ...draft, position, padding, displaySize, maxSize };
			return newSettings;
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [clampedWin, isMobile]);

	useEffect(() => {
		gsap.to(infoTextRef.current, {
			x: isMobile ? clampedWin.width / 2 : clampedWin.width * 0.25,
			y: isMobile
				? particleConfig.position.y + particleConfig.displaySize.height / 2 + 15
				: particleConfig.position.y +
				  particleConfig.displaySize.height / 2 +
				  40,
		});
		gsap.to(nameWheelRef.current, {
			x: isMobile ? clampedWin.width / 2 : clampedWin.width * 0.75,
			y: isMobile
				? clampedWin.height + nameWheelSettings.ringSize * 0.8
				: clampedWin.height / 2 - desktopYBack,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [particleConfig, infoTextSettings, nameWheelSettings]);

	const handleMount = (app) => {
		app.renderer.plugins.interaction.autoPreventDefault = false;
		app.renderer.view.style.touchAction = "auto";
	};

	const handleShowModal = () => {
		modalScrollRef.current.scroll({ top: 0 });
		setShowModal(true);
		ReactGA.event({
			category: "Judges [animation] section",
			action: "Clicked name for modal",
			label: nameData[selectedName].name,
		});
		setTimeout(() => {
			setCloseEnabled(true);
		}, 300);

		document.body.classList.add("stop-scrolling");
		document.documentElement.classList.add("stop-scrolling");
	};

	const handleCloseModal = () => {
		if (!closeEnabled) return;
		setShowModal(false);
		setCloseEnabled(false);

		document.body.classList.remove("stop-scrolling");
		document.documentElement.classList.remove("stop-scrolling");
	};

	return (
		<>
			<Stage
				{...clampedWin}
				className="judge-canvas"
				options={{
					autoDensity: true,
					backgroundAlpha: 0,
					sharedTicker: true,
					autoStart: false,
				}}
				onMount={handleMount}
			>
				<ParticleImage
					padding={30}
					imageData={nameData[selectedName]?.imageData}
					{...clampedWin}
					position={particleConfig.position}
					displaySize={particleConfig.displaySize}
					particleWidth={particleConfig.particleSize}
					particleHeight={particleConfig.particleSize}
					transitionSpeed={transitionSpeed}
				/>

				{/* Judge information */}
				<InfoText
					ref={infoTextRef}
					displayInfo={nameData[selectedName]}
					fontRemSize={infoTextSettings.fontRemSize}
					fontFamily={FONT_FAM}
					gap={5}
					width={wWidth}
					maxWidth={400}
					transitionSpeed={transitionSpeed}
					showModalClick={handleShowModal}
					playstateCallback={(state) => {
						playStateRef.current = state;
					}}
					visible={isFontLoaded}
				/>
				{/* Wheel stuff */}
				<NameWheel
					ref={nameWheelRef}
					nameData={nameData}
					isMobile={isMobile}
					ringSize={nameWheelSettings.ringSize}
					fontRemSize={nameWheelSettings.fontRemSize}
					fontFamily={FONT_FAM}
					nameClickCallback={handleNameClick}
					arrowNextCallback={handleNextName}
					arrowPrevCallback={handlePrevName}
					selectedName={selectedName}
					transitionSpeed={transitionSpeed}
					arrowXSpacing={nameWheelSettings.arrowXSpacing}
					arrowY={nameWheelSettings.arrowY}
					visible={isFontLoaded}
				/>
			</Stage>
			<JudgeModal
				showBackdrop
				showModal={showModal}
				open={handleShowModal}
				hideBackdropClick={handleCloseModal}
			>
				<button className="modal-close" onClick={handleCloseModal}>
					X
				</button>
				<div className="modal-scroll" ref={modalScrollRef}>
					<div className="img-fix">
					<img
						className="judge-img"
						alt={nameData[selectedName].name}
						src={nameData[selectedName].photo}
						width={220}
						height={220}
					/>
					</div>
					<div className="modal-header">
						<p className="card-name">{nameData[selectedName].name}</p>
						<p className="card-title">{nameData[selectedName].title}</p>
						<p className="card-comp">{nameData[selectedName].comp}</p>
					</div>
					<p className="card-paragraph">{nameData[selectedName].bio}</p>
				</div>
			</JudgeModal>
		</>
	);
};

export default JudgeAnimation;
