import "./styles.scss";
import { useLayoutEffect } from "react";
import useToggle from "hooks/useToggle";
import NavigationList from "../NavigationList/NavigationList";

const MobileNav = () => {
	const [openState, toggleOpen] = useToggle(false);
	// const symbol = "®";
	// const xCount = 7;
	// const charWidth = 10;

	useLayoutEffect(() => {
		if (openState) {
			document.body.classList.add("stop-scrolling");
			document.documentElement.classList.add("stop-scrolling");
		} else {
			document.body.classList.remove("stop-scrolling");
			document.documentElement.classList.remove("stop-scrolling");
		}
	}, [openState]);

	function onClickCallback() {
		toggleOpen(false);
	}
	return (
		<div className={openState ? "open" : "closed"}>
			<div className="mobile-nav">
				<a href="#top" onClick={() => toggleOpen()}>
					<h1>{openState ? "X" : "="}</h1>
				</a>
			</div>
			<div className="mobile-nav-menu wrapper">
				<NavigationList onClickCallback={onClickCallback} />
			</div>
		</div>
	);
};

export default MobileNav;
