const sortByLastName = (names) => {
    const compareLastNames = (a, b) => {
        
        // split full name into array
        const aName = a.name.split(" ");
        const bName = b.name.split(" ");
        // get last name from array
        const aLastName = aName[1];
        const bLastName = bName[1];
        // compare last names
        if (aLastName < bLastName) return -1;
        if (aLastName > bLastName) return 1;
        return 0;
    };

    return names.sort(compareLastNames);
};

export {
    sortByLastName,
};
