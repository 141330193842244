// import Modal from "components/UI/Modal/Modal";
import JudgeAnimation from "components/UI/JudgeAnimation/JudgeAnimation";
import Wrapper from "components/UI/Wrapper/Wrapper";
import "./style.scss";


const Judges = ({ isMobile, judgeData }) => {

	return (
		<section className="section secondary-bg" id="judges">
			<Wrapper className="judges-wrapper">
				<h2>Judges</h2>
			</Wrapper>
			{judgeData ? (
				<JudgeAnimation nameData={judgeData} isMobile={isMobile} />
			) : null}
			{/* <Modal /> */}
		</section>
	);
};

export default Judges;
