import ReactGA from "react-ga4";
import "./style.scss";

import MobileNav from "components/UI/MobileNav/MobileNav";
import Divider from "components/UI/Divider/Divider";
import Wrapper from "components/UI/Wrapper/Wrapper";
import NavigationList from "components/UI/NavigationList/NavigationList";

const Header = ({ isMobile, stopScrollRef }) => {
	function linkClickHandler(action, label) {
		ReactGA.event({
			category: "Header section",
			action,
			label,
		});
	}
	return (
		<>
			<section className="section primary-bg">
				<Wrapper className="header-wrapper">
					{isMobile ? <MobileNav stopScrollRef={stopScrollRef} /> : null}
					{/* <div className="header-left"> */}
					<h1 className="header">
						{isMobile ? (
							<>
								<span>RGD</span>
								<span>Branding</span>
								<span>Awards®</span>
							</>
						) : (
							<>
								{/* <span className="header-left"> */}
								{/* <span className="header-left"> */}
								<span>RGD&nbsp;Branding</span>
								{/* </span> */}
								<span>Awards®</span>
							</>
						)}
					</h1>
					{/* </div> */}

					<div className="header-lvl-2">
						<p>
							A new competition celebrating the best in branding and identity
							design from around the world organized by the RGD.
						</p>

						<div className="desktop-navigation">
							<NavigationList />
						</div>
					</div>

					<h1 className="enter-now-link">
						{/* rome-ignore lint/a11y/useValidAnchor: <explanation> */}
						<a
							href="https://www.judgify.me/rgdbranding23"
							target="_blank"
							rel="noreferrer"
							onClick={() =>
								linkClickHandler("CTA link clicked", "Enter now link")
							}
						>
							{isMobile ? (
								<span>Enter Now&nbsp;&nbsp;↗</span>
							) : (
								<span className="header-left">
									<span>Enter Now&nbsp;</span>
									<span>↗</span>
								</span>
							)}
						</a>
					</h1>
				</Wrapper>
			</section>
			<section className="section primary-bg">
				<Divider />
			</section>
			<section className="section primary-bg" id="about">
				<Wrapper className="info-wrapper">
					<h2>
						Make your mark. Submit <br />
						your work to be judged by <br />
						an international jury <br />
						before June 16.
					</h2>
					<h2>
						The inaugural show is open to <br />
						everyone from large studios, <br />
						boutique shops and in-house <br />
						agencies to students <br />
						looking for glory.
					</h2>
				</Wrapper>
			</section>
		</>
	);
};

export default Header;
