import ReactGA from 'react-ga4';
import "rc-collapse/assets/index.css";
import "../Categories/style.scss";
import { Fragment } from "react";
import motion from "../Categories/motionUltils.ts";

import Wrapper from "components/UI/Wrapper/Wrapper";
import Divider from "components/UI/Divider/Divider";
import Collapse from "rc-collapse";
import zeroFill from "zero-fill";
const Panel = Collapse.Panel;

const JudgesAccordion = ({ isMobile, judgeData }) => {
    function accordionOpended(action, label) {
		ReactGA.event({
			category: "Judges [accordion] section",
			action,
			label,
		});
	}
    return (
        <section className="section secondary-bg section-judges" id="judges">
            <Wrapper className="categories-wrapper">
                <h3>Judges</h3>
            </Wrapper>
            <Divider />
            <Wrapper className="accordion-wrapper">
                <Collapse
                    accordion={true}
                    className="categories-accordion"
                    openMotion={motion}
                >
                    {judgeData.slice(0,Math.ceil(judgeData.length/2)).map((d, i) => (
                        <Fragment key={`${i + 1}-judge`}>
                            <Panel
                                header={d.name}
                                extra=""
                                expandIcon={() => (
                                    <p>{zeroFill(2, i + 1)}&nbsp;&nbsp;</p>
                                )}
                                onItemClick={() => accordionOpended("Opened accordion: Judges", d.name)}
                            >
                                <span className="judge-title">{d.title}</span>
                                {d.bio}
                            </Panel>
                            <Divider />
                        </Fragment>
                    ))}
                </Collapse>
            </Wrapper>
        </section>
    );
};

export default JudgesAccordion;
