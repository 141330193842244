import * as PIXI from "pixi.js";
// import { gsap } from "gsap";

import map from "utils/map";

const greyThreshold = 0.45;
const maxTries = 16;

class Batch {
	constructor({
		count,
		particleImage,
		viewWidth,
		viewHeight,
		padding,
		position,
		displaySize,
		particleWidth,
		particleHeight,
	}) {
		this.time = 0;
		this.count = count;
		this.image = particleImage;
		this.displaySize = displaySize;
		this.particleWidth = particleWidth;
		this.particleHeight = particleHeight;
		this.position = position;
		this.padding = padding;
		this.bounds = new PIXI.Rectangle(
			-this.padding,
			-this.padding,
			viewWidth + this.padding * 2,
			viewHeight + this.padding * 2,
		);

		this.particlePropsArray = [];
		this.arrivalRadius = 2;
	}

	init(particleContainer) {
		console.log("initialized");
		for (let i = 0; i < this.count; i++) {
			let spriteProps = {
				targetX: Math.random() * (this.bounds.width - this.bounds.x * 2),
				targetY: Math.random() * (this.bounds.height - this.bounds.y * 2),
				targetScale: 0,
				scale: 0,
				velocity: { x: 0, y: 0, scale: 0 },
				speed: Math.random() * 50 + 5,
				acceleration: 0.9,
				friction: 0.9,
			};

			this.particlePropsArray.push(spriteProps);

			let sprite = PIXI.Sprite.from(this.image);
			sprite.x = spriteProps.targetX;
			sprite.y = spriteProps.targetY;
			this.updateScale(sprite, spriteProps);
			// sprite.width = this.particleWidth * spriteProps.scale;
			// sprite.height = this.particleHeight * spriteProps.scale;
			sprite.tint = 0x1e1e1e;

			particleContainer.addChild(sprite);
		}
	}

	processParticle(imageData, pS) {
		// await new Promise((resolve) => setTimeout(resolve, 10));

		let pickedPixel = null;
		let tries = 0;

		while (!pickedPixel) {
			const cords = {
				x: Math.floor(Math.random() * imageData.width),
				y: Math.floor(Math.random() * imageData.height),
			};

			const randPixel = imageData.getPixelXY(cords.x, cords.y)[0];

			const lotto = (Math.random() * randPixel) / 255 > greyThreshold;

			if (lotto || tries >= maxTries) {
				const scale = map(randPixel, [0, 255], [0, 1]);
				pickedPixel = { ...cords, scale };
			}

			tries++;
		}

		const scaleRatio = this.displaySize.width / imageData.width;
		pickedPixel.x *= scaleRatio;
		pickedPixel.y *= scaleRatio;
		pickedPixel.x += this.position.x - this.displaySize.width / 2;
		pickedPixel.y += this.position.y - this.displaySize.height / 2;

		pS.targetX = pickedPixel.x;
		pS.targetY = pickedPixel.y;
		pS.targetScale = pickedPixel.scale > 0.1 ? 1 : 0;
		// pS.targetScale = 1;
	}

	changeImage(imageData, particleContainer) {
		let children = particleContainer.children;
		children.map((_, i) =>
			this.processParticle(imageData, this.particlePropsArray[i]),
		);
		// console.log(this.particlePropsArray[0]);
	}

	layoutChange(position, displaySize, imageData, particleContainer) {
		this.position = position;
		this.displaySize = displaySize;
		this.changeImage(imageData, particleContainer);
	}

	async update(particleContainer) {
		let particlesArray = particleContainer?.children;
		if (!particlesArray) return;

		await Promise.all(
			particlesArray.map(async (p, i) => {
				//do something here
				const pSettings = this.particlePropsArray[i];

				// calculate distasnce to target
				const dx = pSettings.targetX - p.x;
				const dy = pSettings.targetY - p.y;
				const distance = Math.sqrt(dx * dx + dy * dy);
				const ds = pSettings.targetScale - pSettings.scale;

				// debugger;
				if (distance <= this.arrivalRadius) {
					if (pSettings.scale !== pSettings.targetScale) {
						pSettings.scale = pSettings.targetScale;
						this.updateScale(p, pSettings);
					}
					return;
				}

				//calculate acceleration towards target
				const accelX = (dx * pSettings.acceleration) / distance;
				const accelY = (dy * pSettings.acceleration) / distance;
				// const accelScale = (ds * pSettings.acceleration) / ds;

				// update velocity based on acceleration and friction
				pSettings.velocity.x += accelX;
				pSettings.velocity.y += accelY;
				// pSettings.velocity.scale += accelScale;
				pSettings.velocity.x *= pSettings.friction;
				pSettings.velocity.y *= pSettings.friction;
				// pSettings.velocity.scale *= pSettings.friction;

				// update position based on velocity
				p.x += pSettings.velocity.x;
				p.y += pSettings.velocity.y;
				pSettings.scale += ds * 0.15;

				// update scale based on setting scale
				this.updateScale(p, pSettings);
			}),
		);
	}

	updateScale(p, pSettings) {
		p.width = this.particleWidth * pSettings.scale;
		p.height = this.particleHeight * pSettings.scale;
	}
}

export default Batch;
