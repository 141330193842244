import { useRef, useEffect, useCallback, forwardRef } from "react";
import { Text, Container, Graphics } from "@pixi/react";
import { TextStyle } from "pixi.js";
import gsap from "gsap";
import deg2rads from "@stdlib/math-base-special-deg2rad";

const NameWheel = forwardRef(
	(
		{
			nameData,
			ringSize,
			fontRemSize,
			fontFamily,
			nameClickCallback,
			arrowNextCallback,
			arrowPrevCallback,
			y = 0,
			selectedName = 0,
			transitionSpeed = 0,
			isMobile = false,
			visible = true,
			arrowXSpacing,
			arrowY,
		},
		nameWheelRef,
	) => {
		const fontSize = 20 * fontRemSize;
		const wheelRotationRef = useRef();
		const wheelRotationAdjustRef = useRef();
		const rightArrowRef = useRef();
		const leftArrowRef = useRef();
		const arrowContainerRef = useRef();
		const nameLength = nameData.length;

		const hoverColor = "#f33940";
		const inactiveColor = "1E1E1E";

		function onNameClick(e, i) {
			e.target.style.fill = inactiveColor;
			nameClickCallback(i);
		}

		function onNameHover(e, i) {
			if (selectedName !== i) e.target.style.fill = hoverColor;
		}

		function onNameHoverOut(e, i) {
			if (selectedName !== i) e.target.style.fill = inactiveColor;
		}

		function onArrowHover(e, ref) {
			ref.current.style.fill = hoverColor;
		}

		function onArrowOut(e, ref) {
			ref.current.style.fill = inactiveColor;
		}

		function onNextName(e, ref) {
			arrowNextCallback();
		}
		function onPrevName(e, ref) {
			arrowPrevCallback();
		}

		const drawTouchCircle = useCallback((g) => {
			g.clear();
			g.beginFill(0xde3249);
			g.drawCircle(0, 0, 15);
			g.endFill();
		}, []);

		useEffect(() => {
			gsap.to(arrowContainerRef.current, {
				pixi: { autoAlpha: isMobile ? 1 : 0 },
			});
			gsap.to(wheelRotationAdjustRef.current, {
				pixi: { rotation: isMobile ? 0 : -90 },
			});
		}, [isMobile]);

		useEffect(() => {
			gsap.to(wheelRotationRef.current, {
				pixi: {
					rotation: `${(selectedName / nameLength) * -360}_short`,
				},
				duration: transitionSpeed,
				ease: "sine.inOut",
			});
			
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [selectedName]);

		return (
			<Container ref={nameWheelRef} visible={visible}>
				<Container ref={wheelRotationAdjustRef}>
					<Container ref={wheelRotationRef}>
						{nameData.map(({ name, keyName }, i) => (
							<Container
								key={keyName}
								rotation={deg2rads((i / nameLength) * 360 + 90)}
							>
								<Text
									text={name}
									anchor={[1.0, 0.5]}
									x={-ringSize}
									y={0}
									style={
										new TextStyle({
											fontSize,
											fill:
												keyName === nameData[selectedName].keyName
													? hoverColor
													: inactiveColor,
											fontFamily: fontFamily,
											align: "right",
											dropShadow: true,
											dropShadowAlpha: 0,
											dropShadowBlur: 20,
											dropShadowDistance: 0,
										})
									}
									interactive
									cursor={
										keyName !== nameData[selectedName].keyName
											? "pointer"
											: "arrow"
									}
									onpointerover={(e) => onNameHover(e, i)}
									onpointerout={(e) => onNameHoverOut(e, i)}
									onpointerup={(e) => onNameClick(e, i)}
								/>
							</Container>
						))}
					</Container>
				</Container>
				<Container y={arrowY} ref={arrowContainerRef}>
					<Container
						x={arrowXSpacing}
						interactive
						cursor="pointer"
						onpointerover={(e) => onArrowHover(e, rightArrowRef)}
						onpointerout={(e) => onArrowOut(e, rightArrowRef)}
						onpointerdown={(e) => onArrowHover(e, rightArrowRef)}
						onpointerup={(e) => onNextName(e, rightArrowRef)}
					>
						<Graphics draw={drawTouchCircle} alpha={0} />
						<Text
							text={"→"}
							anchor={[0.5, 0.5]}
							style={
								new TextStyle({
									fontSize: fontSize * 1.6,
									fill: inactiveColor,
									fontFamily: fontFamily,
								})
							}
							ref={rightArrowRef}
						/>
					</Container>
					<Container x={-arrowXSpacing}>
						<Graphics draw={drawTouchCircle} alpha={0} />
						<Text
							text={"←"}
							anchor={[0.5, 0.5]}
							style={
								new TextStyle({
									fontSize: fontSize * 1.6,
									fill: inactiveColor,
									fontFamily: fontFamily,
								})
							}
							interactive
							cursor="pointer"
							onpointerover={(e) => onArrowHover(e, leftArrowRef)}
							onpointerout={(e) => onArrowOut(e, leftArrowRef)}
							onpointerdown={(e) => onArrowHover(e, leftArrowRef)}
							onpointerup={(e) => onPrevName(e, leftArrowRef)}
							ref={leftArrowRef}
						/>
					</Container>
				</Container>
			</Container>
		);
	},
);

export default NameWheel;
