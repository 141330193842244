import ReactGA from "react-ga4";
import "./style.scss";

import Wrapper from "components/UI/Wrapper/Wrapper";
import Divider from "components/UI/Divider/Divider";

import RGDLogo from "assets/images/RRRRGD_Logo.svg";

const Footer = ({ isMobile }) => {
	function linkClickHandler(action, label) {
		ReactGA.event({
			category: "Footer section",
			action,
			label,
		});
	}
	return (
		<>
			<section className="section primary-bg">
				<Divider />
			</section>
			<section className="section primary-bg">
				<Wrapper className="footer-wrapper">
					<div className="row">
						<div className="column-1">
							<div className="footer-cell">
								<img className="rgd-logo" src={RGDLogo} alt="RGD" />
							</div>
						</div>
						<div className="column-2">
							<span>
								<h3>Contact Us</h3>
							</span>
							<span>
								{/* rome-ignore lint/a11y/useValidAnchor: <explanation> */}
								<a
									href="mailto:brandingawards@rgd.ca"
									onClick={() =>
										linkClickHandler("Footer link clicked", "contact us email")
									}
								>
									brandingawards@rgd.ca
								</a>
							</span>
						</div>
						<div className="column-3">
							<div className="footer-cell">
								<span>
									{/* rome-ignore lint/a11y/useValidAnchor: <explanation> */}
<a
										href="https://www.rgd.ca/about/policies/privacy-policy"
										target="_blank"
										rel="noreferrer"
										onClick={() =>
											linkClickHandler("Footer link clicked", "Privacy Policy")
										}
									>
										Privacy Policy
									</a>
								</span>
								{/* <span>
                                    <a
                                        href="https://www.rgd.ca/about/policies/privacy-policy"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Terms and Conditions
                                    </a>
                                </span> */}
							</div>
							<div className="footer-cell cell-last">
								<span>Association of Registered Graphic Designers</span>
								<span>©2023</span>
							</div>
						</div>
					</div>
				</Wrapper>
			</section>
		</>
	);
};

export default Footer;
