import { useEffect, useRef, forwardRef } from "react";
import { Container, Text } from "@pixi/react";
import { TextStyle } from "pixi.js";
import { useImmer } from "use-immer";
import clamp from "clamp";
import gsap from "gsap";

const InfoText = forwardRef(
	(
		{
			fontFamily,
			fontRemSize,
			displayInfo,
			width,
			maxWidth = 300,
			minWidth = 100,
			padding = 100,
			gap = 0,
			transitionSpeed = 1,
			showModalClick,
			visible = true,
			playstateCallback,
		},
		infoTextRef,
	) => {
		const textContainerRef = useRef();
		const nameTextRef = useRef();
		const titleTextRef = useRef();
		const compTextRef = useRef();
		const [currentDisplayInfo, setCurrentDisplayInfo] = useImmer(displayInfo);

		const hoverColor = "#f33940";
		const inactiveColor = "1E1E1E";

		const tl = useRef();
		const displayInfoRef = useRef();

		useEffect(() => {
			if (!tl.current) {
				// console.log("creating new timeline");
				tl.current = gsap
					.timeline({
						repeat: -1,
					})
					.fromTo(
						[nameTextRef.current, titleTextRef.current, compTextRef.current],
						{ pixi: { x: -100, alpha: 0 } },
						{
							pixi: { x: 0, alpha: 1 },
							stagger: 0.1,
							duration: transitionSpeed / 2,
							ease: "sine.out",
							onComplete:() => {
								playstateCallback(false);
							}
						},
					)
					.addPause()
					.to(
						[compTextRef.current, titleTextRef.current, nameTextRef.current],
						{
							pixi: { x: 100, alpha: 0 },
							stagger: 0.1,
							ease: "sine.in",
							duration: transitionSpeed / 2,
							onComplete: () => {
								setCurrentDisplayInfo(displayInfoRef.current);
							},
						},
					);
			}
			displayInfoRef.current = displayInfo;
			tl.current?.play();
			playstateCallback(true);
		}, [displayInfo]);

		useEffect(() => {
			// if (!nameTextRef.current || !titleTextRef.current || !compTextRef.current)
			// 	return;

			//get first line bounds
			const nameTextBounds = nameTextRef.current.getBounds();
			titleTextRef.current.y = nameTextBounds.height;

			//get second line bounds
			const titleTextBounds = titleTextRef.current.getBounds();
			compTextRef.current.y = titleTextRef.current.y + titleTextBounds.height;

			//get bounds of container and center
			const textContainerBounds = textContainerRef.current.getBounds();
			textContainerRef.current.x = -textContainerBounds.width / 2;
		}, [currentDisplayInfo, width, maxWidth, minWidth, fontRemSize]);

		const onNameClick = (e) => {
			if (tl.current.paused()) showModalClick();
		};

		function onNameHover(e) {
			nameTextRef.current.style.fill = hoverColor;
		}

		function onNameOut(e) {
			nameTextRef.current.style.fill = inactiveColor;
		}

		return (
			<Container ref={infoTextRef} visible={visible}>
				<Container ref={textContainerRef}>
					<Text
						ref={nameTextRef}
						text={`${currentDisplayInfo.name}  ↗`}
						style={
							new TextStyle({
								fontSize: fontRemSize * 30,
								fontFamily,
								wordWrap: true,
								wordWrapWidth: clamp(width - padding, minWidth, maxWidth),
								dropShadow: true,
								dropShadowAlpha: 0,
								dropShadowBlur: 20,
								dropShadowDistance: 0,
							})
						}
						interactive
						cursor="pointer"
						onpointerover={(e) => onNameHover(e)}
						onpointerout={(e) => onNameOut(e)}
						onpointerup={(e) => onNameClick(e)}
					/>
					<Text
						ref={titleTextRef}
						text={currentDisplayInfo.title}
						style={
							new TextStyle({
								fontSize: fontRemSize * 20,
								fontFamily,
								wordWrap: true,
								wordWrapWidth: clamp(width - padding, minWidth, maxWidth),
							})
						}
					/>
					<Text
						ref={compTextRef}
						text={currentDisplayInfo.comp}
						style={
							new TextStyle({
								fontSize: fontRemSize * 20,
								fontFamily,
								wordWrap: true,
								wordWrapWidth: clamp(width - padding, minWidth, maxWidth),
							})
						}
					/>
				</Container>
			</Container>
		);
	},
);

export default InfoText;
