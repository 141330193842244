import ReactGA from 'react-ga4';
import "./styles.scss";

import {ReactComponent as RGDLogo} from "assets/images/RRRRGD_Logo.svg";


const NavigationList = ({ onClickCallback = () => {} }) => {
    function onClick(label) {
        // e.preventDefault();
        ReactGA.event({
            category: "Navigation",
            action: "Clicked link",
            label
        })
        onClickCallback();
    }
    return (
        <ul className="navigation-ul">
            <li>
                {/* rome-ignore lint/a11y/useValidAnchor: <explanation> */}
                <a href="#about" onClick={() => onClick("about")}>
                    About
                </a>
            </li>
            <li>
                {/* rome-ignore lint/a11y/useValidAnchor: <explanation> */}
                <a href="#categories" onClick={() => onClick("categories")}>
                    Categories
                </a>
            </li>
            <li>
                {/* rome-ignore lint/a11y/useValidAnchor: <explanation> */}
                <a href="#entryfees" onClick={() => onClick("entry fees")}>
                    Entry Fees
                </a>
            </li>
            <li>
                {/* rome-ignore lint/a11y/useValidAnchor: <explanation> */}
                <a href="https://www.rgd.ca/programs/branding-awards/fees" target="_blank" rel="noreferrer" onClick={() => onClick("entry info")}>
                    Entry Info
                </a>
            </li>
            <li>
                {/* rome-ignore lint/a11y/useValidAnchor: <explanation> */}
                <a href="#judges" onClick={() => onClick("judges")}>
                    Judges
                </a>
            </li>
            <li>
                <span>&nbsp;</span>
            </li>
            <li>
                {/* rome-ignore lint/a11y/useValidAnchor: <explanation> */}
                <a
                    href="https://www.rgd.ca/about/what-is-rgd"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => onClick("RGD about link")}
                >
                    About <RGDLogo className="rgd-logo" />
                    &nbsp;&nbsp;↗
                </a>
            </li>
        </ul>
    );
};

export default NavigationList;
