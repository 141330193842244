import { useWindowWidth } from "@react-hook/window-size";
import {isAndroid} from 'react-device-detect';

import "./styles.scss";

const Divider = ({ underline = false }) => {
    const width = useWindowWidth();
    const fontsize = 2;
    const symbol = "®";
    const count = Math.floor(width / fontsize);

    return (
        <div className={`divider${underline ? " underline" : ""}${isAndroid ? " android-nudge": ""}`}>
            <span>
                {Array(count)
                    .fill(symbol)
                    .reduce((acc, curr) => acc + curr, "")}
            </span>
        </div>
    );
};

export default Divider;
